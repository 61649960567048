import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import styles from '../styles/inicio.module.css';
import useFetch from "../hooks/useFetch";

function parseLogs(data) {
  const groupedLogs = [];
  const logGroups = {};

  Object.keys(data).forEach((server) => {
    const allLogs = [
      ...data[server].out.map(log => ({
        server,
        ...log
      })),
      ...data[server].error.map(log => ({
        server,
        ...log
      }))
    ];

    allLogs.forEach((log) => {
      const key = `${log.timestamp}_${log.server}`;
      
      if (!logGroups[key]) {
        logGroups[key] = {
          timestamp: log.timestamp,
          server: log.server,
          type: log.type,
          message: log.message
        };
      } else {
        // Agregar mensaje al grupo existente
        logGroups[key].message += '\n' + log.message;
      }
    });
  });

  // Convertir grupos a array
  const result = Object.values(logGroups);

  // Ordenar por timestamp (más reciente primero)
  return result.sort((a, b) => {
    if (!a.timestamp) return 1;
    if (!b.timestamp) return -1;
    return new Date(b.timestamp) - new Date(a.timestamp);
  });
}


function Inicio() {
  const [logs, setLogs] = useState([]); // Estado para los logs procesados
  const [filteredLogs, setFilteredLogs] = useState([]); // Estado para los logs filtrados
  const [selectedApp, setSelectedApp] = useState(''); // Estado para la app seleccionada
  const { data, loading, execute, error } = useFetch();

  // Cargar los logs desde el servidor al iniciar
  useEffect(() => {
    const fetchApiData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL || "https://apibo.feelinback.com";
      try {
        await execute(`${apiUrl}/backoffice/getLogs`, "GET");
      } catch (error) {
        console.error("Error al obtener los logs:", error);
      }
    };

    fetchApiData();
  }, []);

  // Procesar los logs cuando llegan los datos
  useEffect(() => {
    if (data) {
      const parsedLogs = parseLogs(data); // Procesamos la estructura de datos
      setLogs(parsedLogs); // Guardamos los logs procesados
    }
  }, [data]);

  // Filtrar los logs según la aplicación seleccionada
  useEffect(() => {
    if (selectedApp) {
      const filtered = logs.filter(log => log.server === selectedApp);
      setFilteredLogs(filtered);
    } else {
      setFilteredLogs(logs); // Mostrar todos si no hay filtro
    }
  }, [selectedApp, logs]);

  // Columnas para la tabla
  const columns = [
    { field: 'timestamp', headerName: 'Hora', flex: 1 },
    { field: 'server', headerName: 'Servidor', flex: 1 },
    { field: 'type', headerName: 'Tipo', flex: 0.5 },
    { 
      field: 'message', 
      headerName: 'Mensaje', 
      flex: 3,
      renderCell: (params) => (
        <div
          style={{
            color: params.row.type === 'ERROR' ? 'red' : 'black',
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            lineHeight: '1.5',
            padding: '8px 0',
            width: '100%',
            height: '100%',
            overflow: 'auto'
          }}
        >
          {params.value}
        </div>
      )
    },
  ];

  // Agregar IDs a los logs para la DataGrid
  const rows = filteredLogs.map((log, index) => ({ id: index, ...log }));

  const appNames = Object.keys(data || {}); // Obtenemos los nombres de las aplicaciones

  if (loading) {
    return (
      <div className={styles.container}>
        <CircularProgress />
        <Typography>Cargando logs...</Typography>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.container}>
        <Typography color="error">Error: {error}</Typography>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Logs</h1>
      <ButtonGroup variant="outlined" color="primary" aria-label="Filtro por aplicación">
        <Button onClick={() => setSelectedApp('')}>Todos</Button>
        {appNames.map((app) => (
          <Button key={app} onClick={() => setSelectedApp(app)}>
            {app}
          </Button>
        ))}
      </ButtonGroup>
      <div style={{ height: 600, width: '100%', marginTop: '20px' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 20, 50]}
          checkboxSelection
          disableSelectionOnClick
          getRowHeight={() => 'auto'} // Altura automática según el contenido
          sx={{
            '& .MuiDataGrid-cell': {
              padding: '8px',
              whiteSpace: 'normal',
              lineHeight: '1.5'
            }
          }}
        />
      </div>
    </div>
  );
}

export default Inicio;
