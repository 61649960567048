/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EmpresaDetails from '../components/EmpresaDetails';
import EmpleadosTable from '../components/EmpleadoTable';
import EmpleadoForm from '../components/EmpleadoForm';
import useFetch from "../hooks/useFetch";
import styles from '../styles/empresa.module.css';

function Empresa() {
    const { id } = useParams();
    const { data, execute } = useFetch();
    const { execute: executePost } = useFetch();
    const { execute: executePut } = useFetch(); // Para la edición
    const { execute: executeDelete } = useFetch(); // Para eliminar empleados
    const { execute: executeGet, data: dataGet } = useFetch(); // Para eliminar empresa
    const { execute: executeUploadExcel } = useFetch();
    const { execute: executeSendEmails } = useFetch(); // Añadir este nuevo hook
    const [empresa, setEmpresa] = useState(null);
    const [formData, setFormData] = useState({});
    const [editMode, setEditMode] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [editEmpleadoId, setEditEmpleadoId] = useState(null); // Estado para controlar si estamos editando
    const [empleados, setEmpleados] = useState([]);
    const [responsables, setResponsables] = useState([]);
    const [equipos, setEquipos] = useState([]);
    const [empleadoData, setEmpleadoData] = useState({
        nombre: '',
        apellido: '',
        dni: '',
        genero: '',
        fecha_nacimiento: '',
        fecha_inicio_empresa: '',
        email: '',
        password: '',
        id_rol: '',  // Ahora tiene un select para los roles
        id_equipo: '', // Cadena vacía
        id_responsable: '', // Cadena vacía
    });
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [deleteConfirmText, setDeleteConfirmText] = useState("");
    const [empleadoAEliminar, setEmpleadoAEliminar] = useState(null);
    const [excelFile, setExcelFile] = useState(null);

    // Datos de los roles
    const roles = [
        { id_rol: 1, rol: 'Empleado' },
        { id_rol: 2, rol: 'Responsable equipo' },
        { id_rol: 3, rol: 'Admin' }
    ];

    useEffect(() => {
        const fetchEmpresa = async () => {
            const apiUrl = process.env.REACT_APP_API_URL || "https://apibo.feelinback.com";
            try {
                await execute(`${apiUrl}/backoffice/empresa/${id}`, "GET");
            } catch (error) {
                console.error("Error al obtener la empresa:", error);
            }
        };
        const fetchAllEmployeesTeams = async () => {
            const apiUrl = process.env.REACT_APP_API_URL || "https://apibo.feelinback.com";
            try {
                await executeGet(`${apiUrl}/backoffice/allEmployeesTeams`, "POST", {id_empresa: id});
            } catch (error) {
                console.error("Error al obtener los empleados y equipos:", error);
            }
        };
        fetchEmpresa();
        fetchAllEmployeesTeams();
    }, []);

    useEffect(() => {
        if (data) {
            setEmpresa(data.empresa);
            setFormData(data.empresa);
            setEmpleados(data.empleados);
        }
        if (dataGet) {
            setResponsables(dataGet.empleados);
            setEquipos(dataGet.equipos);
        }
    }, [data]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleEmpleadoChange = (e) => {
        const { name, type, value, checked } = e.target;
        console.log(name, type, value, checked);
        setEmpleadoData({ ...empleadoData, [name]: type === 'checkbox' ? checked : value });
    };

    const handleSave = async () => {
        const apiUrl = process.env.REACT_APP_API_URL || "https://apibo.feelinback.com";
        try {
            await execute(`${apiUrl}/backoffice/empresa/${id}`, "PUT", formData);
            setEmpresa(formData);
            setEditMode(false);
        } catch (error) {
            console.error("Error al actualizar la empresa:", error);
        }
    };

    const handleAddEmpleado = async () => {
        const apiUrl = process.env.REACT_APP_API_URL || "https://apibo.feelinback.com";
        try {
            await executePost(`${apiUrl}/backoffice/empresa/createEmpleados`, "POST", { 
                id_empresa: id, 
                empleado: {
                    ...empleadoData,
                    admin_canal_etico: empleadoData.admin_canal_etico || false, // Enviando el valor del checkbox
                },
                id_equipo: Number(empleadoData.id_equipo),
                id_responsable: Number(empleadoData.id_responsable),
            });
            setShowModal(false);
            setEmpleadoData({
                nombre: '',
                apellido: '',
                dni: '',
                genero: '',
                fecha_nacimiento: '',
                fecha_inicio_empresa: '',
                email: '',
                password: '',
                id_rol: '',
                admin_canal_etico: false,
            });
    
            // Actualizar la lista de empleados
            setTimeout(async () => {
                await execute(`${apiUrl}/backoffice/empresa/${id}`, "GET");
            }, 1000);
        } catch (error) {
            console.error("Error al crear empleado:", error);
        }
    };
    

    const handleEditEmpleado = async () => {
        const apiUrl = process.env.REACT_APP_API_URL || "https://api.feelinback.com";
        try {
            await executePut(`${apiUrl}/backoffice/editEmpleado`, "POST", { 
                id_empleado: Number(editEmpleadoId),
                campos: {
                    nombre: empleadoData.nombre,
                    apellido: empleadoData.apellido,
                    DNI: empleadoData.dni,
                    email: empleadoData.email,
                },
                responsables: [
                    { tipo_relacion: "directo", id_responsable: Number(empleadoData.id_responsable) }
                ],
                equipo: Number(empleadoData.id_equipo),
                administradorCE: empleadoData.admin_canal_etico // Checkbox booleano
            });
            setShowModal(false);
            setEditEmpleadoId(null);
            setEmpleadoData({
                nombre: '',
                apellido: '',
                dni: '',
                genero: '',
                fecha_nacimiento: '',
                fecha_inicio_empresa: '',
                email: '',
                password: '',
                id_rol: '',
                admin_canal_etico: false,
            });
        } catch (error) {
            console.error("Error al editar empleado:", error);
        }
    };
    

    // Eliminar empleado
    const handleDeleteEmpleado = async (id_empleado) => {
        const apiUrl = process.env.REACT_APP_API_URL || "https://apibo.feelinback.com";
        try {
            await executeDelete(`${apiUrl}/backoffice/empleado/${id_empleado}`, "DELETE");
            setEmpleados(empleados.filter((empleado) => empleado.id_empleado !== id_empleado)); // Filtra el empleado eliminado
        } catch (error) {
            console.error("Error al eliminar empleado:", error);
        }
    };

    // Preparar datos de empleado para editar
    const handleEditClick = (empleado) => {
        setEditEmpleadoId(empleado.id_empleado);
        setEmpleadoData({
            nombre: empleado.nombre,
            apellido: empleado.apellido,
            dni: empleado.DNI,
            genero: empleado.genero,
            fecha_nacimiento: empleado.fecha_nacimiento,
            fecha_inicio_empresa: empleado.fecha_inicio_empresa,
            email: empleado.email,
            password: '',
            id_rol: empleado.id_rol,
            id_equipo: empleado.id_equipo,
            id_responsable: empleado.id_responsable,
            admin_canal_etico: empleado.admin_canal_etico,
        });
        setShowModal(true); // Abre el modal con los datos del empleado
    };

    const handleDeleteClick = (empleado) => {
        setEmpleadoAEliminar(empleado); // Guardar el empleado a eliminar
        setShowDeleteConfirmModal(true); // Mostrar el modal de confirmación
    };

    const handleConfirmDelete = async () => {
        if (deleteConfirmText === "eliminar de forma permanente") {
            await handleDeleteEmpleado(empleadoAEliminar.id_empleado);
            setShowDeleteConfirmModal(false);
            setDeleteConfirmText("");
        } else {
            alert("Por favor, escribe correctamente 'eliminar de forma permanente' para confirmar.");
        }
    };

    const handleFileChange = (e) => {
        setExcelFile(e.target.files[0]);
    };

    const handleUploadExcel = async () => {
        if (!excelFile) {
            alert("Por favor, selecciona un archivo Excel primero");
            return;
        }

        const formData = new FormData();
        formData.append('fileEmployees', excelFile);
        formData.append('id_empresa', id);

        const apiUrl = process.env.REACT_APP_API_URL || "https://apibo.feelinback.com";
        try {
            const response = await executeUploadExcel(
                `${apiUrl}/backoffice/crearUsuariosExcel`,
                "POST",
                formData,
                {
                    // Importante: No incluir Content-Type aquí, el navegador lo establecerá automáticamente
                    // con el boundary correcto para el FormData
                },
                true // isFormData flag
            );

            if (response) {
                alert("Archivo procesado correctamente");
                // Actualizar la lista de empleados
                await execute(`${apiUrl}/backoffice/empresa/${id}`, "GET");
            }
        } catch (error) {
            console.error("Error al subir el archivo:", error);
            alert("Error al procesar el archivo");
        }
    };

    // Añadir este nuevo handler para enviar correos
    const handleSendWelcomeEmails = async () => {
        const apiUrl = process.env.REACT_APP_API_URL || "https://apibo.feelinback.com";
        try {
            const response = await executeSendEmails(
                `${apiUrl}/backoffice/enviar-correos-bienvenida`,
                "POST",
                { id_empresa: id }
            );

            if (response) {
                alert(`Correos enviados: ${response.total_enviados}\nErrores: ${response.total_errores}`);
                // Actualizar la lista de empleados para reflejar los cambios
                await execute(`${apiUrl}/backoffice/empresa/${id}`, "GET");
            }
        } catch (error) {
            console.error("Error al enviar correos:", error);
            alert("Error al enviar los correos de bienvenida");
        }
    };

    return (
        <div className={styles.container}>
            <h2>Detalles de la Empresa</h2>
            <EmpresaDetails
                empresa={empresa}
                formData={formData}
                handleChange={handleChange}
                editMode={editMode}
                handleSave={handleSave}
                setEditMode={setEditMode}
            />

            <div className={styles.actionButtons}>
                {/* Añadir este nuevo botón */}
                <button 
                    onClick={handleSendWelcomeEmails}
                    className={styles.sendEmailButton}
                >
                    Enviar Correos de Bienvenida
                </button>

                <button onClick={() => setShowModal(true)}>Añadir Empleado</button>
            </div>

            {/* Añadir esta sección para la carga de Excel */}
            <div className={styles.excelUploadSection}>
                <div className={styles.uploadControls}>
                    <input
                        type="file"
                        accept=".xlsx,.xls"
                        onChange={handleFileChange}
                        className={styles.fileInput}
                    />
                    <button 
                        onClick={handleUploadExcel}
                        className={styles.uploadButton}
                        disabled={!excelFile}
                    >
                        Subir Excel
                    </button>
                </div>
            </div>

            {/* Formulario de empleado */}
            <EmpleadoForm
                empleadoData={empleadoData}
                handleEmpleadoChange={handleEmpleadoChange}
                handleSaveEmpleado={editEmpleadoId ? handleEditEmpleado : handleAddEmpleado}
                roles={roles}
                showModal={showModal}
                setShowModal={setShowModal}
                editEmpleadoId={editEmpleadoId}
                equipos={equipos}
                responsables={responsables}
            />

            {/* Tabla de empleados */}
            <EmpleadosTable
                empleados={empleados}
                roles={roles}
                handleEditClick={handleEditClick}
                handleDeleteClick={handleDeleteClick} // Cambiar handleDeleteEmpleado a handleDeleteClick
            />


            {/* Modal de confirmación de eliminación */}
            {showDeleteConfirmModal && (
                <div className={styles.modal}>
                    <div className={styles.modalContent}>
                        <h2>Confirmar Eliminación</h2>
                        <p>Escribe "<span>eliminar de forma permanente</span>" para confirmar la eliminación del empleado.</p>
                        <input
                            type="text"
                            value={deleteConfirmText}
                            onChange={(e) => setDeleteConfirmText(e.target.value)}
                            placeholder='Escribe "eliminar de forma permanente"'
                        />
                        <div className={styles.modalActions}>
                            <button onClick={handleConfirmDelete}>Confirmar</button>
                            <button onClick={() => setShowDeleteConfirmModal(false)} style={{ marginLeft: '10px' }}>
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Empresa;